.app {
  display: flex;
  justify-content: space-between;
  background: #fefefe;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.nav {
  padding: 12px;
  width: 200px;
  height: 100vh;
  background: #fff;
}
.nav .menu li {
  height: 36px;
  list-style: none;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}
.nav .menu li a{
  display: flex;
  flex: 1;
  text-decoration: none;
  color: var(--text-color);
  font-size: 14px;
  padding: 12px;
  border-radius: var(--border-radius);
}
.nav .menu li a:hover{
  background: var(--hover-bg-color);
}
.nav .menu li a.active {
  color: var(--primary-color);
  background: var(--active-bg-color);
  font-weight: bold;
}
.nav .title {
  margin: 12px 0 24px 12px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}
.content {
  flex: 1;
  background: #F7F8FA;
  padding: 24px;
}
.container {
  margin-top: 24px;
  overflow-y: auto;
  height: 100%;
}